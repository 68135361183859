@import url("./assets/css/utility.scss");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Inter", sans-serif !important;
  scroll-behavior: smooth;
}

.body-question {
  padding-top: 90px;
}

p {
  margin: 0 !important;
}

li {
  list-style: none;
}

.btn-link,
.ngb-dp-weekday {
  color: var(--wizer-blue) !important;
}

.question-legend-info {
  color: var(--wizer-red) !important;
}

// For Modal Dialog
.modal-content {
  width: 519px !important;
  height: 552px !important;
  border-radius: 16px !important;
  background: #fff !important;
  padding: 2.7rem;
  box-shadow: 0 3px 7px #d9e9f996 !important;

  .modal-body {
    .center-image {
      display: flex;
      justify-content: center;
      padding-bottom: 2rem;
    }

    .modal-content-body-header-text {
      padding-bottom: 2rem;
      text-align: center;
    }

    .modal-content-body-text {
      color: var(--wizer-blue) !important;
      font-size: 14px !important;
      text-align: center;
    }

    .modal-button-container {
      display: flex;
      justify-content: space-around;
      padding-top: 2rem;
    }
  }
}
// For Modal Dialog - End

//Submission Screen
.submission-body-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .thank-you-message {
    display: flex;
    flex-direction: column;
    width: 80%;
    border-radius: 8px;
    background: #fff;
    padding: 3rem;

    .thank-you-text {
      color: var(--wizer-blue) !important;
      font-size: 0.9rem !important;
    }

    #thank-you-header {
      display: flex;
      width: 55%;
      color: var(--wizer-blue);
      font-size: 40px;
      padding-bottom: 2rem;

      p {
        padding-left: 0.5rem;
      }
    }

    .text-center {
      text-align: left !important;
    }
  }
}

.border-bottom-submit {
  width: 100% !important;
  padding-bottom: 3rem !important;
  border-bottom: 2px solid rgba(77, 93, 118, 0.15) !important;
}

.home-button-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding-top: 1rem;
  /* padding-left: 12rem;
  padding-right: 12rem; */
  .back-to-wizer-home-button {
    width: 25% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--wizer-blue) !important;
    color: var(--regular-white) !important;
    font-size: 16px !important;
    height: 48px !important;
    border: none !important;
    border-radius: 35px !important;
    cursor: pointer !important;
  }
}
//Submission Screen

#header-image {
  width: 100%;
  img {
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 8px;
    margin-top: 30px;
    margin-bottom: 25px;
  }
}

#form-field-container {
  width: 90%;
  background: #eaeaea;
  border-radius: 8px;
  padding: 25px;
  // margin: 40px 130px 0px;

  .input-text-field::placeholder,
  .input-date-field::placeholder {
    color: var(--wizer-blue-2) !important;
  }

  .label-input-container {
    /* display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;     */
    .form-column-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 1rem;
      .form-column {
        display: flex;
        flex-direction: column;

        label {
          margin-bottom: 0.5rem;
        }

        .input-text-field {
          width: 100% !important;
          height: 32px !important;
          font-size: var(--text-size-14) !important;
          border: none !important;
          border-radius: 8px !important;
          background: var(--regular-white) !important;
          padding: 0px 10px 0px 10px !important;
        }

        .input-date-field {
          width: 123px !important;
          height: 32px !important;
          font-family: "Inter", sans-serif !important;
          font-size: var(--text-size-14) !important;
          // color: var(--wizer-blue-2) !important;
          border: none !important;
          border-radius: 8px !important;
          background: var(--regular-white) !important;
          padding: 0px 10px 0px 10px !important;
        }

        .input-date-field-icon {
          position: absolute;
          left: 100px;
          top: 5px;
          color: #8792a2;
          font-size: 15px;
          z-index: 999;
        }

        .date-selection-wrapper {
          display: flex;
          position: relative;
        }

        .wizer-select {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
        }
      }

      .form-column-40 {
        width: 40%;
      }

      .radio-bin-container {
        width: 8%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        label {
          margin-bottom: 1.3rem;
        }
        img {
          vertical-align: baseline !important;
        }
      }

      .field-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }

    .field-container-sm {
      display: flex;
      width: 95%;
      justify-content: space-between;
    }

    .input-container {
      display: flex;
      justify-content: space-between;
    }
  }
}

#expertise {
  .expertise-container {
    display: flex;
    flex-direction: column;
  }
}

#expertise-first-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  text-align: center;
  padding: 15px;
  border-radius: 4px 4px 0px 0px;
  background: #f3f3f3;
  div {
    display: flex;
    width: 25%;
    align-items: center;

    label {
      text-align: left;
      margin-bottom: 0 !important;
    }

    input {
      min-width: 1rem;
      max-width: 1rem;
      margin-right: 0.5rem !important;
      height: 16px;
      color: var(--wizer-blue);
    }
  }
}

#expertise-second-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 60px;
  background: #fff;
  text-align: center;
  padding: 15px;

  div {
    display: flex;
    width: 25%;
    align-items: center;

    label {
      text-align: left;
      margin-bottom: 0 !important;
    }

    input {
      min-width: 1rem;
      max-width: 1rem;
      margin-right: 0.5rem !important;
      height: 16px;
      color: var(--wizer-blue);
    }
  }
}

button:disabled {
  background: var(--disabled-color) !important;
  cursor: default !important;
}

#home-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 2rem 8rem 0rem 0rem;
  .nav-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--wizer-blue);
    color: var(--regular-white);
    font-size: 16px;
    width: 130px;
    height: 48px;
    border: none;
    border-radius: 35px;
    padding-left: 2.2rem;
    cursor: pointer;

    span {
      margin-right: 1rem;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.4rem;
      width: 35px;
      height: 35px;
      border: 1px solid grey;
      border-radius: 50%;
    }

    input[type="submit"]:disabled {
      background: var(--disabled-color);
    }
  }
}

#back-submit-cancel-button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 2rem 2rem 0rem 2rem;
  .nav-button-back {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--wizer-blue);
    color: var(--regular-white);
    font-size: var(--text-16-regular);
    width: 130px;
    height: 48px;
    border: none;
    border-radius: 35px;
    cursor: pointer;

    span {
      margin-right: 2.2rem;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.4rem;
      width: 35px;
      height: 35px;
      border: 1px solid grey;
      border-radius: 50%;
    }
  }
}

#expertise-question-container {
  width: 90%;
  background: #fff;
  padding: 25px;
  // margin: 0px 130px;
}

.container {
  max-width: 1280px;
  padding: 0 4rem;
  margin: auto;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 3rem 0rem;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #8792a2;
  letter-spacing: 0.18px;

  .footer-content-left {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 30%;
    text-align: center;
    margin-left: 3rem;

    .powered-by {
      span {
        margin-right: 0.5rem;
      }
    }

    .terms-privacy {
      span {
        margin-right: 1rem;
      }
    }
  }

  .footer-content-right {
    margin-right: 3rem;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-radius: 16px 16px 16px 16px;
  background: #f0f2f4;
  box-shadow: 0px 3px rgba(0, 0, 0, 0.08);

  .exp-question-content-header {
    width: inherit;
    height: 140px;
    border-radius: 16px 16px 0px 0px;
    background: var(--wizer-blue);
    margin-bottom: 20px;
    padding: 5px 50px;
  }

  .nav-button-container-wizer {
    width: 100%;
  }
}

.content-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: inherit;
  height: 234px;
  border-radius: 16px 16px 0px 0px;
  background: var(--wizer-blue);
  margin-bottom: 20px;
  padding: 5px 50px;
}

p.content-header-text {
  color: var(--regular-white);
  font-size: 48px;
  text-align: justify;
  margin-bottom: 13px;
}

.content-header {
  #first-parag,
  #second-parag {
    color: var(--regular-white);
    font-size: 18px;
    padding-top: 1rem;
    margin-bottom: 25px;
  }
  .second-parag-padding-bottom {
    padding-bottom: 0.6rem;
  }
}

.header-with-button {
  display: flex;
  justify-content: space-between;
  margin: 3.2rem 0rem 1.5rem 0rem;
}

.form-button {
  border: 1px solid rgba(197, 197, 197, 1);
  border-radius: 25px;
  color: #107c41;
  font-size: 12px;
  width: 104.433px;
  height: 31.527px;
  cursor: pointer;
}

.form-button:disabled {
  color: darkgrey;
}

/* Accordion Styling */
.accordion {
  margin: 25px auto !important;
  width: 100% !important;
}

.accordion li {
  list-style: none !important;
  margin-top: 20px !important;
  border-radius: 10px 10px 0px 0px !important;
  background: #f3f4f5 !important;
}

.accordion li div.accordion-header {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 10px !important;
}

.accordion li div.accordion-header label {
  font-size: 20px !important;
  color: var(--wizer-blue) !important;
  cursor: pointer !important;
  margin-bottom: 0 !important;
}

.accordion li div h4 {
  font-size: 16px !important;
  color: var(--wizer-red) !important;
  font-weight: normal !important;
}

.accordion-header label::before {
  display: inline-flex !important;
  content: "";
  background: url("./assets/images/fi-rr-plus.svg") center center no-repeat !important;
  margin-right: 10px !important;
  position: relative;
  top: 3px;
  width: 24px !important;
  height: 24px !important;
  transition: background 0.6s;
}

li > input[type="radio"] {
  display: none !important;
}

li > input[type="checkbox"] {
  display: none !important;
}

.accordion .content {
  line-height: 26px !important;
  height: 0 !important;
  overflow: hidden !important;
  transition: height 0.5s !important;
  margin: 0rem 1rem !important;
}

.content {
  .p-top-margin {
    margin-top: 25px !important;
  }

  .question-number-content {
    display: flex !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    margin-right: 1rem !important;
    font-size: var(--text-size-14) !important;
    color: var(--wizer-blue) !important;

    p {
      padding-left: 1rem;
    }
  }
}

.accordion input[type="radio"]:checked + .accordion-header + .content,
.accordion input[type="checkbox"]:checked + .accordion-header + .content {
  height: auto !important;
}

.accordion input[type="radio"]:checked + .accordion-header label::before,
.accordion input[type="checkbox"]:checked + .accordion-header label::before {
  content: "";
  display: inline-flex !important;
  background: url("./assets/images/fi-rr-minus.svg") !important;
  width: 24px !important;
  height: 24px !important;
  transition: background 0.6s;
}

/* Accordion Styling End */
.likert-scale-option-item-even {
  background: #fff !important;
  border-radius: 0px !important;
}

.likert-scale-option-item-margin-bottom {
  margin-bottom: 1rem !important;
}

.radio-container {
  display: flex !important;
  width: 30% !important;
  justify-content: space-between !important;
  padding: 0rem 0.5rem;
}

.text-section {
  display: flex !important;
  width: 70% !important;
  margin-right: 1rem !important;
}

.likert-container {
  display: flex !important;
  width: 100% !important;
  // margin-bottom: 0.3rem !important;
  padding-left: 1.6rem;
  .question-content {
    display: flex !important;
    width: 100% !important;
    flex-direction: column;
    ul {
      display: flex !important;
      justify-content: end !important;
      list-style: none !important;
      font-size: var(--text-size-14) !important;
      color: var(--wizer-blue) !important;
      // margin-left: 12rem !important;
      max-width: 100%;

      li {
        display: flex !important;
        justify-content: center !important;
      }
    }
    .likert-scale-option-item:nth-child(even) {
      background: #fff !important;
    }

    // .likert-scale-option-item input:last-of-type {
    //   margin-right: 30px !important;
    // }

    .likert-scale-option div:first-of-type {
      border-radius: 4px 4px 0px 0px;
    }

    .likert-scale-option {
      font-size: var(--text-size-14) !important;
      color: var(--wizer-blue) !important;
      // padding-bottom: 1.5rem;
    }
    .likert-scale-option-item {
      display: flex !important;
      align-items: center;
      width: 100% !important;
      justify-content: space-between !important;
      //   margin: 0px 25px 0px 0px;
      background: #e3e7ed;
      padding: 5px 10px !important;
    }
    .option-container {
      label {
        font-size: var(--text-size-14) !important;
        color: var(--wizer-blue) !important;
      }
      div {
        display: flex;
        align-items: baseline;
        label {
          max-width: 80%;
          text-align: left;
        }
      }
    }

    .likert-scale-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 0px 10px !important;

      .likert-scale-legend {
        display: flex;
        width: 40%;
        font-size: var(--text-size-14);
        color: var(--wizer-blue);
        justify-content: space-evenly;
        // padding-right: 0.6rem;
        padding-left: 4.5rem;
      }
    }
  }
}

.complete-icon-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  width: 20%;
}

.icon-container {
  display: flex;
  align-items: end;
}

.form-parent-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.legend {
  display: block;
  margin-top: 1rem;
  padding-bottom: 1rem;
  span {
    padding-left: 1rem;
  }
}

.legend-menu {
  position: fixed;
  overflow: hidden;
  padding-right: 5rem;
  padding-top: 1rem;
  text-align: center;
  top: 0;
  left: 0;
  background: #fff;
  width: 100% !important;
  box-shadow: 0 0 10px rgba(67, 66, 66, 0.2);
  transition: hidden 5s;
  z-index: 3;
}

.legend-hidden {
  visibility: hidden;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
