:root {
  --wizer-blue: #4d5d76;
  --wizer-blue-2: rgba(77, 93, 118, 0.33);
  --regular-white: #fff;
  --wizer-green: #107c41;
  --wizer-red: #c00000;
  --text-size-14: 14px;
  --disabled-color: rgba(77, 93, 118, 0.33);
}

.required-field {
  color: var(--wizer-red);
  font-size: 12px;
}

.text-12 {
  color: var(--wizer-blue) !important;
  font-size: 12px !important;
}

.text-14 {
  color: var(--wizer-blue) !important;
  font-size: 14px !important;
}

.text-15 {
  color: var(--wizer-blue) !important;
  font-size: 15px !important;
}

.text-13 {
  color: var(--wizer-blue) !important;
  font-size: 13px !important;
}

.text-16-regular {
  color: var(--wizer-blue) !important;
  font-size: 16px !important;
}

.text-color-red {
  color: var(--wizer-red) !important;
}

.text-16 {
  color: var(--wizer-blue) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.text-26 {
  color: var(--wizer-blue) !important;
  font-size: 26px !important;
  font-weight: 500 !important;
}

.vspacer-10 {
  margin-bottom: 10px;
}

.vspacer-10-top {
  margin-top: 10px;
}

.vspacer-12 {
  margin-bottom: 12px;
}

.vspacer-30 {
  margin-bottom: 30px;
}

.vspacer-32 {
  margin-bottom: 30px;
}

.vspacer-40 {
  margin-bottom: 40px;
}

.vspacer-28-top {
  margin-top: 28px;
}

.vspacer-28 {
  margin-bottom: 28px;
}

.border-bottom-wizer {
  width: 100% !important;
  padding-top: 1rem !important;
  border-bottom: 2px solid rgba(77, 93, 118, 0.15) !important;
}

.border-bottom-last {
  width: 90%;
  margin: 1rem 3rem 3rem;
  border-bottom: 2px solid rgba(77, 93, 118, 0.15);
}

.delete-icon {
  color: rgba(192, 0, 0, 1);
  background-image: url("../images/fi-rr-trash.svg");
  background-repeat: no-repeat;
}

.icon-spacer {
  padding-right: 5px;
}

.icon-spacer-left {
  padding-left: 5px;
}

.icon-spacer-left-wide {
  padding-left: 20px;
}

.success-button {
  background: var(--wizer-green) !important;
  color: var(--regular-white) !important;
  font-size: 16px !important;
  width: 130px !important;
  height: 48px !important;
  border: none !important;
  border-radius: 35px !important;
  cursor: pointer !important;
}

.danger-button {
  background: var(--wizer-red) !important;
  color: var(--regular-white) !important;
  font-size: 16px !important;
  width: 130px !important;
  height: 48px !important;
  border: none !important;
  border-radius: 35px !important;
  cursor: pointer !important;
}

.regular-button {
  background: var(--wizer-blue) !important;
  color: var(--regular-white) !important;
  font-size: 16px !important;
  width: 130px !important;
  height: 48px !important;
  border: none !important;
  border-radius: 35px !important;
  cursor: pointer !important;
}

.sub-heading {
  padding-left: 1.8rem;
  padding-bottom: 1rem;
}
